import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import GitInfo from 'react-git-info/macro'
import _ from 'underscore'

import {SENTRY_CLIENT_PROXY_URL} from '../constants/urls'
import {REFRESH_JWT_ERROR_MESSAGE} from './auth_utils.js'
import {create_user_state_url, read_report_user_state_from_window} from './user_state_utils.js'
import { get_domain_prefix, is_dev_environment, is_prod_cdn_environment } from './utils.js'

/**
 * To use the Test Sentry project (useful for experimenting):
 * a) In .env file, uncomment the KEY, PROJECT_ID and ENVIRONMENT for the Test Sentry project.
 * b) In the Sentry.init() call below, comment out the tunnel, and set 'debug: true'
 * c) Below, set USE_SENTRY to true
 * Then you can access it at https://sentry.io/organizations/aistemos/issues/?project=4504537268617216
 */

const NO_SENTRY = process.env.REACT_APP_NO_SENTRY === 'true'

const is_prod = is_prod_cdn_environment()
const USE_SENTRY = is_prod && !NO_SENTRY

const KEY                = process.env['REACT_APP_SENTRY_KEY']         || '92616d5e8280429488e0f6bdf91cac63'
const PROJECT_ID         = process.env['REACT_APP_SENTRY_PROJECT_ID']  || '1204423'
const SENTRY_ENVIRONMENT = process.env['REACT_APP_SENTRY_ENVIRONMENT'] || get_sentry_environment()
const SENTRY_TRACES_SAMPLE_RATE = parseFloat(process.env['REACT_APP_SENTRY_TRACES_SAMPLE_RATE'] || 0.0005)
const SENTRY_DSN = `https://${KEY}@o129431.ingest.sentry.io/${PROJECT_ID}`

const client_git_info = GitInfo()

function get_sentry_environment() {
  // Infer sentry environment from url (this way, single docker image can be used with multiple environments)
  if (is_dev_environment()) {
    return 'development' // Please do not delete! This is the environment name on Sentry.
  }

  return 'production' // Please do not delete! This is the environment name on Sentry.
}

export function init_sentry(history) {
  if (!USE_SENTRY) { return }

  Sentry.init({
    dsn: SENTRY_DSN,
    tunnel: `${get_domain_prefix()}${SENTRY_CLIENT_PROXY_URL}`,
    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    ignoreErrors: [
      /undefined is not an object \(evaluating '.+\._fullData\[.+\.index]'\)/,            // ignore Plotly piechart bug on Safari, mouseout fires even when parent element has been removed
      /undefined is not an object \(evaluating '.+\._fullLayout\._hoversubplot=null'\)/,  // ignore Plotly piechart bug on Safari, mouseout fires even when parent element has been removed
      /Unable to fetch image from IFI claims \(via html img\), url: .+/,                  // ignore missing images error from IFI claims endpoint.
      //'Network Error',                                                                    // ignore network errors
      /Cannot set property 'innerHTML' of null/,                                          // ignore callbacks that try to set state after user has navigated to a different page (TODO: can remove this when we move to Cipher 3.1)
      /Request failed with status code 403/,                                              // ignore 403 errors (user does not have permmission to view a page)
      /ResizeObserver loop limit exceeded/,                                               // ignore weird window observer error, not part of our code
      /3 INVALID_ARGUMENT: classifier build is already in progress/,                      // ignore 'build in progress' - it's not really an error.
      REFRESH_JWT_ERROR_MESSAGE // ignore JWT refresh error (this can happen when refresh token expires - we will redirect to login, it's harmless)
    ],
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        tracePropagationTargets: [get_domain_prefix(), /^\//]
      })
    ],
    release: client_git_info.tags.join(',') || 'local', // git tags are only available post-build (not on local development server)
    // debug: true
  })
}

function get_clean_user(user) {
  if (!user) {
    return user
  }

  // Remove personal info, and unnused fields
  const user_clean = _.pick(user, ['sub', 'group_names', 'role_names'])

  // Set uuid (so Sentry shows it at the top), and add link to user
  const { sub } = user
  const user_url = `https://vpnlogin.aws.cipher.ai/auth/admin/master/console/#/cipher/users/${sub}/settings`
  const user_clean_with_id = { ...user_clean, id: sub, user_url }

  return user_clean_with_id
}

export function set_sentry_user_context(user) {
  if (!USE_SENTRY) { return }

  const clean_user = get_clean_user(user)

  Sentry.configureScope(scope => {
    scope.setUser(clean_user)
  })
}

export function set_sentry_client_tag(client_tag) {
  if (!USE_SENTRY) { return }

  Sentry.configureScope(scope => {
    scope.setTag('client_tag', client_tag)
  })
}

/**
 * Send error to Sentry.
 * @param {} error the error object.
 * @param {} extra Optional object with extra key/values to send to sentry.
 * @return null
 */
export function send_error_to_sentry(error, extra) {
  if (!USE_SENTRY) { return null }
  // TODO: Is the below useful? Can we remove it?

  // Try to get user_state
  const user_state = read_report_user_state_from_window() // may be null
  const user_state_url = create_user_state_url(user_state) // may be null
  extra = extra || {}

  Sentry.configureScope(scope => {
    // Add extra scope information
    scope.setExtras({ ...extra, user_state, user_state_url })
  })

  // Send to Sentry
  return Sentry.captureException(error)
}
