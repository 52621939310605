import axios from 'axios'

import { get_as_map } from '../utils/utils.js'
import { ORG_SEARCH_NPES_URL } from '../constants/urls.js'
import { add_source_err_to_target_err } from '../utils/axios_utils.js'
import { is_aistemos, is_lotnet_member } from '../utils/user_permissions.js'

function make_tag(prefix, suffix) {
  return `${prefix}:${suffix}`
}

function fetch_organisations_all_npes() {
  return axios.get(ORG_SEARCH_NPES_URL)
    .then((response) => {
      return response.data
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch NPE organisations: ')
      throw wrapped_err
    })
}

const EXCLUDED_TAG = '!'

export const TAG_PRODUCT_AUTOMOTIVE = 'automotive'
export const TAG_PRODUCT_IA         = 'ia'
export const TAG_PRODUCT_BOA        = 'boa'
export const TAG_PRODUCT_JTI        = 'jti'
export const TAG_PRODUCT_GENERAL    = 'general'

export const PRODUCT_TAGS = [
  {id: TAG_PRODUCT_GENERAL,    name: 'General'},
  {id: TAG_PRODUCT_AUTOMOTIVE, name: 'Automotive'},
  {id: TAG_PRODUCT_IA,         name: 'Industrial Automation'},
  {id: TAG_PRODUCT_BOA,        name: 'Bank of America'},
  {id: TAG_PRODUCT_JTI,        name: 'Japan Tobacco International'}
]

export const TAG_REGION_APAC = 'apac'
export const TAG_REGION_NA   = 'na'
export const TAG_REGION_EU   = 'eu'

export const REGION_TAGS = [
  {id: TAG_REGION_NA,   name: 'North America', products: [TAG_PRODUCT_GENERAL, TAG_PRODUCT_AUTOMOTIVE, TAG_PRODUCT_IA]},
  {id: TAG_REGION_APAC, name: 'APAC',          products: [TAG_PRODUCT_GENERAL, TAG_PRODUCT_AUTOMOTIVE, TAG_PRODUCT_IA]},
  {id: TAG_REGION_EU,   name: 'Europe',        products: [TAG_PRODUCT_GENERAL, TAG_PRODUCT_AUTOMOTIVE, TAG_PRODUCT_IA]}
]

const TAG_COUNTRY_DE = 'de'
const TAG_COUNTRY_JP = 'jp'
const TAG_COUNTRY_CN = 'cn'
const TAG_COUNTRY_KR = 'kr'
const TAG_COUNTRY_US = 'us'
const TAG_COUNTRY_UK = 'uk'

export const COUNTRY_TAGS = [
  {id: TAG_COUNTRY_DE, name: 'Germany', products: [TAG_PRODUCT_GENERAL, TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_COUNTRY_JP, name: 'Japan',   products: [TAG_PRODUCT_GENERAL, TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_COUNTRY_CN, name: 'China',   products: [TAG_PRODUCT_GENERAL, TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_COUNTRY_KR, name: 'Korea',   products: [TAG_PRODUCT_GENERAL, TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_COUNTRY_US, name: 'USA',     products: [TAG_PRODUCT_GENERAL, TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_COUNTRY_UK, name: 'UK',      products: [TAG_PRODUCT_GENERAL]},
]

const TAG_CATEGORY_TECH                  = 'tech'
const TAG_CATEGORY_ELECTRIC_VEHICLE      = 'electric_vehicle'
const TAG_CATEGORY_COMMERCIAL_VEHICLE    = 'commercial_vehicle'
const TAG_CATEGORY_HEAVY_VEHICLE         = 'heavy_vehicle'
const TAG_CATEGORY_SUPPLIER              = 'supplier'
const TAG_CATEGORY_3D_PRINTING           = '3d_printing'
const TAG_CATEGORY_AUTOMATION            = 'automation'
const TAG_CATEGORY_CONTROL_CONNECTIVITY  = 'control_connectivity'
const TAG_CATEGORY_HYDRAULICS_PNEUMATICS = 'hydraulics_pneumatics'
const TAG_CATEGORY_INDUSTRIAL            = 'industrial'
const TAG_CATEGORY_MATERIAL_HANDLING     = 'material_handling'
const TAG_CATEGORY_MOTORS_DRIVES         = 'motors_drives'
const TAG_CATEGORY_ROBOTICS              = 'robotics'
const TAG_CATEGORY_SENSORS               = 'sensors'
const TAG_CATEGORY_OEM                   = 'oem'
const TAG_CATEGORY_UNIVERSITY            = 'university'
const TAG_CATEGORY_PAE                   = 'patent_assertion_entity'
const TAG_CATEGORY_LOTNET_MEMBERS        = 'lotnet_members'
const TAG_CATEGORY_BOA_ORGS              = 'boa_orgs'
const TAG_CATEGORY_JTI_ORGS              = 'jti_orgs'
export const TAG_CATEGORY_JOINT_VENTURE  = 'joint_venture'

export const CATEGORY_TAGS = [
  {id: TAG_CATEGORY_TECH,                  name: 'Technology',               products: [TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_CATEGORY_ELECTRIC_VEHICLE,      name: 'Electric vehicle',         products: [TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_CATEGORY_COMMERCIAL_VEHICLE,    name: 'Commercial vehicle',       products: [TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_CATEGORY_HEAVY_VEHICLE,         name: 'Heavy vehicle',            products: [TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_CATEGORY_SUPPLIER,              name: 'Supplier',                 products: [TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_CATEGORY_3D_PRINTING,           name: '3D Printing',              products: [TAG_PRODUCT_IA]},
  {id: TAG_CATEGORY_AUTOMATION,            name: 'Automation',               products: [TAG_PRODUCT_IA]},
  {id: TAG_CATEGORY_CONTROL_CONNECTIVITY,  name: 'Control and Connectivity', products: [TAG_PRODUCT_IA]},
  {id: TAG_CATEGORY_HYDRAULICS_PNEUMATICS, name: 'Hydraulics, Pneumatics',   products: [TAG_PRODUCT_IA]},
  {id: TAG_CATEGORY_INDUSTRIAL,            name: 'Industrial',               products: [TAG_PRODUCT_IA]},
  {id: TAG_CATEGORY_MATERIAL_HANDLING,     name: 'Material Handling',        products: [TAG_PRODUCT_IA]},
  {id: TAG_CATEGORY_MOTORS_DRIVES,         name: 'Motors, Drives',           products: [TAG_PRODUCT_IA]},
  {id: TAG_CATEGORY_ROBOTICS,              name: 'Robotics',                 products: [TAG_PRODUCT_IA]},
  {id: TAG_CATEGORY_SENSORS,               name: 'Sensors',                  products: [TAG_PRODUCT_IA]},
  {id: TAG_CATEGORY_OEM,                   name: 'OEM',                      products: [TAG_PRODUCT_AUTOMOTIVE]},
  {id: TAG_CATEGORY_UNIVERSITY,            name: 'University',               products: [TAG_PRODUCT_GENERAL]},
  {id: TAG_CATEGORY_JOINT_VENTURE,         name: 'Joint Venture',            products: [TAG_PRODUCT_GENERAL]},
  {id: TAG_CATEGORY_LOTNET_MEMBERS,        name: 'LOTNet members',           products: [TAG_PRODUCT_GENERAL]},
  {id: TAG_CATEGORY_BOA_ORGS,              name: 'BoA organisations',        products: [TAG_PRODUCT_BOA]},
  {id: TAG_CATEGORY_JTI_ORGS,              name: 'JTI organisations',        products: [TAG_PRODUCT_JTI]}
]

export const TAG_GROUP_PRODUCT  = 'product'
export const TAG_GROUP_REGION   = 'region'
export const TAG_GROUP_COUNTRY  = 'country'
export const TAG_GROUP_CATEGORY = 'category'

export const ORG_SET_TAG_GROUPS = [
  {id: TAG_GROUP_PRODUCT,  label: 'Product',  tags: PRODUCT_TAGS},
  {id: TAG_GROUP_REGION,   label: 'Region',   tags: REGION_TAGS},
  {id: TAG_GROUP_COUNTRY,  label: 'Country',  tags: COUNTRY_TAGS},
  {id: TAG_GROUP_CATEGORY, label: 'Category', tags: CATEGORY_TAGS}
]

export const ORG_SET_TAG_GROUPS_BY_ID = get_as_map(ORG_SET_TAG_GROUPS, 'id')

const ORG_SET_TAGS = [].concat(...ORG_SET_TAG_GROUPS.map(group => {
  const {id, tags} = group
  return tags.map(tag =>  ({'id': make_tag(id, tag.id), 'name': tag.name, 'products': tag.products || []}))
}))

export const ORG_SET_TAGS_BY_ID = get_as_map(ORG_SET_TAGS, 'id')

function product_tag(suffix) {
  return make_tag(TAG_GROUP_PRODUCT, suffix)
}

function region_tag(suffix) {
  return make_tag(TAG_GROUP_REGION, suffix)
}

function country_tag(suffix) {
  return make_tag(TAG_GROUP_COUNTRY, suffix)
}

export function category_tag(suffix) {
  return make_tag(TAG_GROUP_CATEGORY, suffix)
}

export function get_pae_tag() {
  return category_tag(TAG_CATEGORY_PAE)
}

const AUTOMOTIVE_APAC_SETS = [
  {label: 'Japanese OEMs',           tags: [country_tag(TAG_COUNTRY_JP), category_tag(TAG_CATEGORY_OEM)]},
  {label: 'Korean OEMs',             tags: [country_tag(TAG_COUNTRY_KR), category_tag(TAG_CATEGORY_OEM)]},
  {label: 'Chinese OEMs',            tags: [country_tag(TAG_COUNTRY_CN), category_tag(TAG_CATEGORY_OEM)]},
  {label: 'Japanese suppliers',      tags: [country_tag(TAG_COUNTRY_JP), category_tag(TAG_CATEGORY_SUPPLIER)]},
  {label: 'Korean suppliers',        tags: [country_tag(TAG_COUNTRY_KR), category_tag(TAG_CATEGORY_SUPPLIER)]},
  {label: 'Chinese suppliers',       tags: [country_tag(TAG_COUNTRY_CN), category_tag(TAG_CATEGORY_SUPPLIER)]},
  {label: 'Japan (all)',             tags: [country_tag(TAG_COUNTRY_JP)]},
  {label: 'Korea (all)',             tags: [country_tag(TAG_COUNTRY_KR)]},
  {label: 'China (all)',             tags: [country_tag(TAG_COUNTRY_CN)]},
  {label: 'Japanese tech companies', tags: [country_tag(TAG_COUNTRY_JP), category_tag(TAG_CATEGORY_TECH)]},
  {label: 'Korean tech companies',   tags: [country_tag(TAG_COUNTRY_KR), category_tag(TAG_CATEGORY_TECH)]}
]

const AUTOMOTIVE_EUROPE_SETS = [
  {label: 'European OEMs',      tags: [region_tag(TAG_REGION_EU), category_tag(TAG_CATEGORY_OEM)]},
  {label: 'German OEMs',        tags: [country_tag(TAG_COUNTRY_DE), category_tag(TAG_CATEGORY_OEM)]},
  {label: 'German suppliers',   tags: [country_tag(TAG_COUNTRY_DE), category_tag(TAG_CATEGORY_SUPPLIER)]},
  {label: 'European suppliers', tags: [region_tag(TAG_REGION_EU), category_tag(TAG_CATEGORY_SUPPLIER)]},
  {label: 'Germany (all)',      tags: [country_tag(TAG_COUNTRY_DE)]},
  {label: 'Europe (all)',       tags: [region_tag(TAG_REGION_EU)]}
]

const AUTOMOTIVE_US_SETS = [
  {label: 'North American OEMs',      tags: [region_tag(TAG_REGION_NA), category_tag(TAG_CATEGORY_OEM)]},
  {label: 'North American suppliers', tags: [region_tag(TAG_REGION_NA), category_tag(TAG_CATEGORY_SUPPLIER)]},
  {label: 'North America (all)',      tags: [region_tag(TAG_REGION_NA)]},
  {label: 'US tech companies',        tags: [country_tag(TAG_COUNTRY_US), category_tag(TAG_CATEGORY_TECH)]},
]

const AUTOMOTIVE_WORLD_SETS = [
  {label: 'Electric vehicle manufacturers',   tags: [category_tag(TAG_CATEGORY_ELECTRIC_VEHICLE)]},
  {label: 'Commercial vehicle manufacturers', tags: [category_tag(TAG_CATEGORY_COMMERCIAL_VEHICLE)]},
  {label: 'Heavy vehicle manufacturers',      tags: [category_tag(TAG_CATEGORY_HEAVY_VEHICLE)]},
  {label: 'All OEMs',                         tags: [category_tag(TAG_CATEGORY_OEM)]},
  {label: 'All suppliers',                    tags: [category_tag(TAG_CATEGORY_SUPPLIER)]},
  {label: 'All automotive IP owners',         tags: []}
]

const IA_TECHNOLOGY_SETS = [
  {label: '3d Printing companies',              tags: [category_tag(TAG_CATEGORY_3D_PRINTING)]},
  {label: 'Automation companies',               tags: [category_tag(TAG_CATEGORY_AUTOMATION)]},
  {label: 'Control and Connectivity companies', tags: [category_tag(TAG_CATEGORY_CONTROL_CONNECTIVITY)]},
  {label: 'Hydraulics, Pneumatics companies',   tags: [category_tag(TAG_CATEGORY_HYDRAULICS_PNEUMATICS)]},
  {label: 'Industrial companies',               tags: [category_tag(TAG_CATEGORY_INDUSTRIAL)]},
  {label: 'Material Handling companies',        tags: [category_tag(TAG_CATEGORY_MATERIAL_HANDLING)]},
  {label: 'Motor, Drives companies',            tags: [category_tag(TAG_CATEGORY_MOTORS_DRIVES)]},
  {label: 'Robotics companies',                 tags: [category_tag(TAG_CATEGORY_ROBOTICS)]},
  {label: 'Sensors companies',                  tags: [category_tag(TAG_CATEGORY_SENSORS)]}
]

const IA_TERRITORY_SETS = [
  {label: 'European companies',              tags: [region_tag(TAG_REGION_EU)]},
  {label: 'North American companies',        tags: [region_tag(TAG_REGION_NA)]},
  {label: 'Asian companies',                 tags: [region_tag(TAG_REGION_APAC)]}
]

const IA_OTHER_SETS = [
  {label: 'All IA organisations', tags: [`${EXCLUDED_TAG}${category_tag(TAG_CATEGORY_UNIVERSITY)}`]}
]

const BOA_GENERAL_SETS = [
  {label: 'BoA organisations', tags: [category_tag(TAG_CATEGORY_BOA_ORGS)]}
]

const JTI_GENERAL_SETS = [
  {label: 'JTI organisations', tags: [category_tag(TAG_CATEGORY_JTI_ORGS)]}
]

const CIPHER_GENERAL_SETS = [
  {label: 'All universities',                tags: [category_tag(TAG_CATEGORY_UNIVERSITY)]},
  {label: 'Top European universities',       tags: [region_tag(TAG_REGION_EU), category_tag(TAG_CATEGORY_UNIVERSITY)]},
  {label: 'Top North American universities', tags: [region_tag(TAG_REGION_NA), category_tag(TAG_CATEGORY_UNIVERSITY)]},
  {label: 'Top Asian universities',          tags: [region_tag(TAG_REGION_APAC), category_tag(TAG_CATEGORY_UNIVERSITY)]},
  {label: 'UK universities',                 tags: [country_tag(TAG_COUNTRY_UK), category_tag(TAG_CATEGORY_UNIVERSITY)]},
  {label: 'All NPEs',                        tags: [], fetch_endpoint: fetch_organisations_all_npes},
  {label: 'All PAEs',                        tags: [get_pae_tag()]},
  {label: 'LOTNet members',                  tags: [category_tag(TAG_CATEGORY_LOTNET_MEMBERS)], permissions_endpoint: ({user}) => is_aistemos(user) || is_lotnet_member(user)}
]

export const AUTOMOTIVE_GROUPS = [
  {id: 'apac',  label: 'APAC',          sets: AUTOMOTIVE_APAC_SETS},
  {id: 'eu',    label: 'Europe',        sets: AUTOMOTIVE_EUROPE_SETS},
  {id: 'na',    label: 'North America', sets: AUTOMOTIVE_US_SETS},
  {id: 'world', label: 'World',         sets: AUTOMOTIVE_WORLD_SETS}
]

export const IA_GROUPS = [
  {id: 'tech',  label: 'By technology', sets: IA_TECHNOLOGY_SETS},
  {id: 'geo',   label: 'By territory',  sets: IA_TERRITORY_SETS},
  {id: 'other', label: 'Other',         sets: IA_OTHER_SETS}
]

export const BOA_GROUPS = [
  {id: 'general', label: 'General', sets: BOA_GENERAL_SETS}
]

export const JTI_GROUPS = [
  {id: 'general', label: 'General', sets: JTI_GENERAL_SETS}
]

export const CIPHER_GROUPS = [
  {id: 'general', label: 'General', sets: CIPHER_GENERAL_SETS}
]

export const AUTOMOTIVE_TAG = product_tag(TAG_PRODUCT_AUTOMOTIVE)
export const IA_TAG         = product_tag(TAG_PRODUCT_IA)
export const BOA_TAG        = product_tag(TAG_PRODUCT_BOA)
export const JTI_TAG        = product_tag(TAG_PRODUCT_JTI)
